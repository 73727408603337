const dcn = (suffix = '') => `.view-parts-Menu${suffix}`;

const handleLoad = () => {
    const $el = document.querySelector(dcn());

    if (!$el) {
        return;
    }

    const $nav = $el.querySelector(dcn('-nav'));
    const $items = Array.from($el.querySelectorAll(dcn('-item')));
    let $currentItem = $items.find(($i) => $i.classList.contains('is-current'));
    const $leftIndicator = document.createElement('div');
    $leftIndicator.classList.add(
        'hidden',
        'absolute',
        'left-0',
        'top-0',
        'bottom-0',
        '-z-10',
        'bg-white',
        'transition-all',
        'md:block'
    );
    $nav.appendChild($leftIndicator);
    const $rightIndicator = document.createElement('div');
    $rightIndicator.classList.add(
        'hidden',
        'absolute',
        'right-0',
        'top-0',
        'bottom-0',
        '-z-10',
        'bg-white',
        'transition-all',
        'md:block'
    );
    $nav.appendChild($rightIndicator);

    const layoutIndicators = ($indicatedItem) => {
        $items.forEach(($item) => {
            if ($item === $indicatedItem) {
                $item.classList.remove('md:text-black');
                $item.classList.add('md:text-white');
            } else {
                $item.classList.remove('md:text-white');
                $item.classList.add('md:text-black');
            }
        });

        const navRect = $nav.getBoundingClientRect();

        if ($indicatedItem) {
            const itemRect = $indicatedItem.getBoundingClientRect();

            $leftIndicator.style.width = `${itemRect.left - navRect.left}px`;
            $rightIndicator.style.width = `${navRect.right - itemRect.right}px`;
        } else {
            $leftIndicator.style.width = `${navRect.width}px`;
            $rightIndicator.style.width = `${navRect.width}px`;
        }
    };

    const handleItemMouseEnter = (event) => {
        const $eventItem = event.currentTarget;

        layoutIndicators($eventItem);
    };
    const handleItemClick = (event) => {
        if (event.shiftKey || event.ctrlKey || event.metaKey) {
            return;
        }

        const $eventItem = event.currentTarget;

        $currentItem = $eventItem;

        layoutIndicators($currentItem);
    };
    const handleMouseLeave = () => {
        layoutIndicators($currentItem);
    };

    $items.forEach(($item) => {
        $item.classList.remove('md:bg-white');
        $item.classList.add('md:bg-transparent');
        $item.classList.add('md:text-black');

        $item.addEventListener('mouseenter', handleItemMouseEnter);
        $item.addEventListener('click', handleItemClick);
    });

    $el.addEventListener('mouseleave', handleMouseLeave);

    layoutIndicators($currentItem);
};

// document.addEventListener('load', handleLoad);
handleLoad();
